import React from 'react';
import { colorArrayToRgb } from '@methanesat/colors';
import { ArrowCircleUpIcon, Button, styled, Typography } from '@methanesat/ui-components';
import { SUMMARIZED_TARGET_PALETTE } from '../../consts';

interface ExploreDataPortalButtonProps {
    close: () => void;
    t: (key: string) => string;
}

// Some padding is added to the start of the gradient here, to allow for the gradient to shift to the right on hover.
const background = `linear-gradient(90deg, 
${colorArrayToRgb(SUMMARIZED_TARGET_PALETTE[0])} 0%, 
${SUMMARIZED_TARGET_PALETTE.map(
    (color, index) => `${colorArrayToRgb(color)} ${((index + 1) / SUMMARIZED_TARGET_PALETTE.length) * 100}%`
).join(',')})`;

const StyledButton = styled(Button)(({ theme }) => ({
    background,
    [theme.breakpoints.up('xs')]: {
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(1)
    },
    [theme.breakpoints.up('md')]: {
        margin: theme.spacing(1.25, 'auto'),
        padding: theme.spacing(1.5)
    },
    [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(2, 'auto'),
        padding: theme.spacing(1.6)
    },
    ...theme.mixins.gradientButton
}));

const ExploreDataPortalButton = ({ close, t }: ExploreDataPortalButtonProps) => {
    return (
        <StyledButton data-testid="explore-data-portal-button" onClick={close} fullWidth>
            <Typography
                sx={{
                    fontSize: {
                        xs: '0.75rem',
                        sm: '0.875rem',
                        md: '1rem',
                        lg: '1.15rem',
                        xl: '1.2rem'
                    },
                    fontWeight: 450,
                    marginLeft: '-14%'
                }}
            >
                {t('emissionsMapPage.introModal.buttonText')}
            </Typography>
            <ArrowCircleUpIcon
                className="arrow-icon"
                sx={{
                    rotate: '90deg',
                    color: 'white',
                    position: 'absolute',
                    right: '3%',
                    transition: 'right 0.3s ease-in-out',
                    fontSize: {
                        xs: '1.25rem',
                        sm: '1.75rem',
                        md: '2rem',
                        lg: '2.1rem',
                        xl: '2.25rem'
                    }
                }}
            />
        </StyledButton>
    );
};

export default ExploreDataPortalButton;
