import { useTheme, Theme, SvgIcon, SxProps } from '@mui/material';
import React, { ReactElement } from 'react';

import MSATLogoBlk from '../../icons/methanesat-primary-lockup-trademark-blk-rgb.svg';
import MSATLogoBlkSmall from '../../icons/methanesat-vertical-small-scale-lockup-blk-rgb.svg';
import MSATLogoWhite from '../../icons/methanesat-primary-lockup-trademark-reversewhite-rgb.svg';
import MSATLogoWhiteSmall from '../../icons/methanesat-vertical-small-scale-lockup-reversewhite-rgb.svg';

/** Props for the Logo component. */
export type LogoProps = {
    /** CSS class to apply to the container element. */
    className?: string;
    /** Title that appears on hover and for a11y. */
    title?: string;
    /** Logo variants. */
    variant?: 'default' | 'small' | 'hero';
    sx?: SxProps<Theme>;
};

/**
 * Helper to return the correct logo variation depending on the context.
 */
const getLogoComponent = (variant: LogoProps['variant'], isDarkMode: boolean) => {
    if (variant === 'small') {
        if (isDarkMode) {
            // return the white logo for a dark screen
            return MSATLogoWhiteSmall;
        } else {
            // return the black logo for a light screen
            return MSATLogoBlkSmall;
        }
    } else {
        // Return the default version of the logo
        if (isDarkMode) {
            // return the white logo for a dark screen
            return MSATLogoWhite;
        } else {
            // return the black logo for a light screen
            return MSATLogoBlk;
        }
    }
};

/**
 * MethaneSAT logo component.
 * TODO more logo variants.
 */
const Logo = ({ variant = 'default', title = 'MethaneSAT', sx, ...otherProps }: LogoProps): ReactElement => {
    const theme: Theme = useTheme();
    const isDarkMode: boolean = theme.palette.mode === 'dark';

    const LogoType = getLogoComponent(variant, isDarkMode);

    // Convert to SvgIcon
    return (
        // Note: SvgIcon inherits color from parent. This may override dark/light mode.
        <SvgIcon
            inheritViewBox
            preserveAspectRatio="xMaxYMin meet"
            data-testid={variant}
            sx={[
                {
                    display: 'block',
                    width: variant === 'small' ? '30px' : variant === 'hero' ? 'unset' : '132px',
                    ...(variant === 'hero' && {
                        height: {
                            xs: theme.spacing(5),
                            sm: theme.spacing(8),
                            md: theme.spacing(11)
                        },
                        // spacing around the logo should be the height of the M in MethaneSAT
                        // for now, that is the height of the entire logo
                        // (it will need to be calculated differently for the vertical logo)
                        marginY: {
                            xs: theme.spacing(5),
                            sm: theme.spacing(8),
                            md: theme.spacing(11)
                        }
                    })
                },
                ...(Array.isArray(sx) ? sx : [sx])
            ]}
            titleAccess={title}
            component={LogoType}
            aria-label="MethaneSAT Logo"
            {...otherProps}
        ></SvgIcon>
    );
};

export default Logo;
